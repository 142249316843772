import { callToBackend } from './fetch';

export function variableGET(topData, documentData, setDocumentData) {
    const template_id = documentData.template_id || topData.template_id;
      const template_version = documentData.template_version || topData.template_version;
      const params = {
        email: topData.userEmail,
        token: topData.token,
        template_id: template_id,
        template_version: template_version,
        Key: topData.AccessKeyId,
        Secret: topData.SecretAccessKey,
        nonce: topData.nonce,
        groupName: topData.groupName,
      };
      const url = topData.endpoint + "/variable";
      callToBackend(url, params, topData.token, {}, 'GET') 
      .then((res) => {
        if (200 === res.status) {
          res.json().then((returned) => {           
            const values = {};
            Object.keys(returned.data).forEach((id) => {
              returned.data[id].variables.forEach((v) => {
                if (returned.data[id].multiple) {
                  values[v.id] = [""];
                }
                else {
                  values[v.id] = "";
                }
              });
            });

            setDocumentData({
              ...documentData,
              variableData : returned.data,
              variableValues : values,
              variablesFetched : true,
            });                  
          });
        }
        else {
          res.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Error loading requested document.'); 
            }       
          });      
        }
      })
      .catch((err) => {
        console.error(err);
        alert('error');
      });   
}

export async function documentPUT(topData, setTopData, documentData, setDocumentData, workspaceData, setWorkspaceData, next) {
      const params = {
          email : topData.userEmail,
          token : topData.token,
          userId : topData.userId,
          userName : topData.userName,
          id : documentData.documentId,
          version : documentData.version,
          template_id : documentData.template_id || topData.template_id,
          template_version : documentData.template_version || topData.template_version,
          variableValues : documentData.variableValues,
          documentName : documentData.documentName,
          sections : documentData.sections,
          Key : topData.AccessKeyId,
          Secret : topData.SecretAccessKey,
          nonce : topData.nonce,
          groupName : topData.groupName,
      };
      const url = topData.endpoint + "/document";
      const res = await callToBackend(url, params, topData.token, {}, 'PUT');
      if (res.status === 200) {
        const data = await res.json();
        const newDocument = ("" === documentData.documentId);
        if (newDocument) {
          const newDocList = [
            ...topData.existing, 
            {
              ...documentData, 
              documentId: data.documentId, 
              version: data.version,
              'template_id': data.template_id,
              'template_version': data.template_version,
              'template_name': data.template_name,
            }
          ];

          await setTopData({
            ...topData, 
            page: next,
            existing: newDocList,
          });
          setWorkspaceData({
            ...workspaceData,
            companyDocuments: newDocList,
          });
        }
        else {
          const existing = topData.existing.map((e) => {
            if (e.documentId === data.documentId) {
              e = { 
                ...documentData, 
                version: data.version, 
                updated_at : Date.now(),
                variableData : documentData.variableData,
                variableValues : documentData.variableValues,                    
              }
            }
            return e;
          });
          setTopData({
            ...topData, 
            page : next,
            existing : existing,
          });  
          setWorkspaceData({
            ...workspaceData,
            companyDocuments : existing,
          });                    
        }

        setDocumentData({ 
          ...documentData, 
          saving    : false,
          loading   : false,
          jumpto    : "",
          documentId : data.documentId,
          version   : data.version,
          'template_id'      : data.template_id,
          'template_version' : data.template_version,
          'template_name'    : data.template_name
        });
        
        return(false);
      }
      else {
        setDocumentData({ ...documentData, loading : false, saving : false, jumpto : "", });
        console.log(res);
        alert("error 379-DataForm");
        return(false);
      }
}

export function documentPUTtwo(topData, setTopData, documentData, setDocumentData, sections, mergedVariables) {
    const params = {
          email : topData.userEmail,
          token : topData.token,
          userId : topData.userId,
          userName : topData.userName,
          id : documentData.documentId,
          version : documentData.version,
          template_id : documentData.template_id || topData.template_id,
          template_version : documentData.template_version || topData.template_version,
          variableValues : mergedVariables,
          documentName : documentData.documentName,
          sections : sections,
          Key : topData.AccessKeyId,
          Secret : topData.SecretAccessKey,
          nonce : topData.nonce,
          groupName : topData.groupName,
        };
      const url = topData.endpoint + "/document";
      callToBackend(url, params, topData.token, {}, 'PUT')
      .then((res) => {
        if (401 === res.status) { 
          res.json().then((data) => {
        
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Error saving document section.'); 
            }       
          })
        }
        else if (200 === res.status) {
          setDocumentData({
            ...documentData,
            liveSection : 0, 
            liveId : 0,
            saving : false, 
            sections : sections,  
            variableValues : mergedVariables,   
          });
        }   
      })
      .catch((err) => {
        setDocumentData({ ...documentData, saving : false, jumpto : "", });
        setTopData({ ...topData, loading : false, });
        console.log(err);
        alert("Error: 300-DataForm.");
      });
}

export function documentGET(topData, workspaceData, setWorkspaceData, now, setupPage) {
    const params = {
        email: topData.userEmail,
        userId: topData.userId,
        Key: topData.AccessKeyId,
        Secret: topData.SecretAccessKey,
        nonce: topData.nonce,
        groupName: topData.groupName,
      };
      const url = topData.endpoint + "/document";
      callToBackend(url, params, topData.token, {}, 'GET', true)
      .then((res) => {
        if (401 === res.status) { 
          res.json().then((data) => {
            if (data.message) {
              alert(data.message);
            } else {
              alert('Error retrieving existing documents.'); 
            }       
          });
        } else if (200 === res.status) {
          res.json().then((documentData) => {
            setWorkspaceData({ 
              ...workspaceData, 
              existing: documentData['documents'], 
              docs: documentData['documents'], 
              docs_at: now,
            });
            setupPage();
          });
        }
      })
      .catch((err) => {
        console.log(err);
        alert('Error retrieving existing documents.');
      });
}

export function wordPOST(topData, setTopData, HTMLcontent) {
    const params = { 
        content : HTMLcontent.replace(/\s+/g, ' '),
        email : topData.userEmail,
        token : topData.token,
        Key : topData.AccessKeyId,
        Secret : topData.SecretAccessKey,
        nonce : topData.nonce,
        groupName : topData.groupName,
      };
    const url = topData.endpoint + "/word";
    callToBackend(url, params, topData.token, {}, 'POST')
    .then((res) => {
      setTopData({ ...topData, loading : true }); 
    
      if (400 === res.status) {
        res.json().then((data) => {
          console.log(data)
        });
        alert('Error writing Word document.');
      }
      else if (200 === res.status) {
        res.json().then((data) => {
          // This is where the Word document is posted - check here if it doesn't download.
          window.location.href = data.url;
        });
        setTopData({ ...topData, loading : false }); 
      }
    })
    .catch((err) => {
      setTopData({ ...topData, loading : false }); 
      console.log(err);
    });
}

export function statusGET(topData, setTopData, interval) {
          const params = {
                email: topData.userEmail,
                token: topData.token,
                Key: topData.AccessKeyId,
                Secret: topData.SecretAccessKey,
                nonce: topData.nonce,
                groupName: topData.groupName,
                userId: topData.userId,
              };
              const url = topData.endpoint + '/email';
              callToBackend(url, params, topData.token, {}, 'GET')
          .then((res) => {
            if (401 === res.status) { 
              res.json().then((data) => {

                if (data.message) {
                  alert(data.message);
                }
                else {
                  alert('Your login has expired'); 
                }
                clearInterval(interval);          
              })
            }
            else if (200 === res.status) {
              res.json().then((data) => {
                if ("offline" !== data.status) {
                  if ("offline" === topData.page) {
                    clearInterval(interval); 
                    setTopData({
                      ...topData,
                      page : 'welcome',
                    });      
                  }
                }
              });
            }
          })
          .catch((err) => {
            console.log(err);
          }); 
}

export function permissionGET(topData, setTopData, documentData, setDocumentData, documentId) {

    const params = {
          email: topData.userEmail,
          token: topData.token,
          Key: topData.AccessKeyId,
          Secret: topData.SecretAccessKey,
          nonce: topData.nonce,
          groupName: topData.groupName,
          userId: topData.userId,
        };
        const url = topData.endpoint + '/email';
        callToBackend(url, params, topData.token, {}, 'GET')
    .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
        
          const documentPermissions = documentData.documentPermissions ? documentData.documentPermissions : {};
          documentPermissions[documentId] = {
            admin : data.admin,
            contributor : data.contributor,
            manager : data.manager,
            superuser : data.superuser,
          };
        
          setDocumentData({ 
            ...documentData, 
            'documentId' : documentId, 
            'documentPermissions' : documentPermissions, 
            loading : false ,
            companyId : data.companyId,
          });
          
          setTopData({
            ...topData,
            document_id : documentId,
            page : 'manageSpecificDocument',             
          });
        })
      }
      else {
        res.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }       
        })      
      }      
    })
    .catch((err) => {
      console.error(err);
      alert('error');
      setDocumentData({ ...documentData, loading : false });
    });
}

export function documentGETtwo(topData, setTopData, documentData, setDocumentData) {
    const params = {
        email: topData.userEmail,
        Key: topData.AccessKeyId,
        Secret: topData.SecretAccessKey,
        nonce: topData.nonce,
        groupName: topData.groupName,
        userId: topData.userId,
        companyID: "",
      };
      const url = topData.endpoint + '/document';
      callToBackend(url, params, topData.token, {}, 'GET')
      .then((res) => {
      if (200 === res.status) {
        res.json().then((data) => {
          setDocumentData({
            ...documentData,
            allDocuments : data.allDocuments,
          });
          
          setTopData({
            ...topData,
            team : data.team,            
          });
        })      
      }
      else {
        res.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }         
        })      
      }      
    })
    .catch((err) => {
      console.error(err);
      alert('error');
    });
}

export function variablePUT(topData, variableData, setVariableData, editedVset){
    const params = {
        email : topData.userEmail,
        token : topData.token,
        vset : editedVset,
        Key : topData.AccessKeyId,
        Secret : topData.SecretAccessKey,
        nonce : topData.nonce,
        groupName : topData.groupName,
      };
    const url = topData.endpoint + '/variable';
    callToBackend(url, params, topData.token, {}, 'PUT')
 
    // TO DO: Review what is returned and ensure that varDefns is the full variable
    .then((response) => {
      if (200 === response.status) {     
        response.json().then((data) => {          
          setVariableData({
            ...variableData,
            edit    : "",
            loading : "",
            varDefns : { 
              ...variableData.variables,         
              [editedVset.id] : data.vset,
            }
          });
        });
      }
      else {
        response.json().then((data) => {
      
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Error saving changes to variable ', editedVset.name); 
          }     
        })            
      }
    });
}

export function templateDELETE(topData, draft, id, version, name) {
    const params = {
          email: topData.userEmail,
          token: topData.token,
          draft_id: draft ? id : undefined,
          template_id: draft ? "" : id,
          template_version: version,
          Key: topData.AccessKeyId,
          Secret: topData.SecretAccessKey,
          nonce: topData.nonce,
          groupName: topData.groupName,
          userId: topData.userId,
        };
      const url = topData.endpoint + '/template';
      callToBackend(url, params, topData.token, {}, 'DELETE')  
      .then((res) => {
        if (res.status !== 200) {
          res.json().then((data) => {
            const errorMessage = draft 
              ? 'Experienced an error deleting the document.'
              : 'Error permanently deleting template.';
            alert(data.message || errorMessage);
          });
        } else {
          const successMessage = draft 
            ? `Successfully deleted template draft ${name}.`
            : `Successfully deleted template ${name}.`;
          alert(successMessage);
        }
      })
      .catch((err) => {
        console.error(err);
        alert(draft ? 'Error deleting the document.' : 'Error');
      });
}

export function templateGETthree(topData, templateData, workspaceData, frontMatterOptions, setTemplateData, dataFetchingRef){
        const params = {
            email: topData.userEmail,
            template_id: templateData.template_id,
            Key: topData.AccessKeyId,
            Secret: topData.SecretAccessKey,
            nonce: topData.nonce,
            groupName: topData.groupName,
            userId: topData.userId,
          };
        const url = topData.endpoint + '/template';
        callToBackend(url, params, topData.token, {}, 'GET')    
        .then((res) => {
          if (200 === res.status) {
            res.json().then((data) => { 
              // Search the template list in workspaces to find the template name
              const templateName = workspaceData.liveTemps.reduce((prev, curr) => {
                if (curr.id === templateData.template_id) {
                  return curr.name;
                }
                else {
                  return prev;
                }
              }, '');
              
              // If a frontMatter selection has been made, retain that. Otherwise use the default selections
              data.frontMatter = data.frontMatter.length ? data.frontMatter : frontMatterOptions;
            
              // IF there are sections included in the template
              if (data.details.selected && data.details.selected.length) { 
                // If changes have been made to the template selections             
                if (templateData.updated && templateData.updated.id === templateData.template_id && 
                  (templateData.updated.when + 20000) > Date.now()) {
                  // user just submitted a save to this template and that save will not yet 
                  // be complete on the backend, so use what we already have. 
                  const details = workspaceData.liveTemps.reduce((prev, curr) => {
                    if (curr.id === templateData.template_id) {
                      return curr.sections;
                    }
                    else {
                      return prev;
                    }
                  }, []);
                  setTemplateData({
                    ...templateData,
                    template_details : details,
                    selected : data.details.selected,
                    collapsed : [],
                    frontMatter : data.frontMatter,
                    lines : data.lines,
                    name : templateName,
                    frontMatterOptions : frontMatterOptions,
                  });                       
                }
                else {
                  setTemplateData({
                    ...templateData,
                    template_details : data.details.content,
                    selected : data.details.selected,
                    collapsed : [],
                    frontMatter : data.frontMatter,
                    lines : data.lines,
                    name : templateName,
                    frontMatterOptions : frontMatterOptions,
                  });                     
                }
                dataFetchingRef.current = false;                     
              }
              else {
                const collection = [];
                const allSectionIds = (sections) => {
                  return sections.forEach((s) => {
                    if (s.id) { 
                      if (!collection.includes(s.id)) {
                        collection.push(s.id);                       
                      }
                    }
                    
                    if (s.sub_sections && s.sub_sections.length) {
                      allSectionIds(s.sub_sections, collection);
                    }
                  });
                };
                
                allSectionIds(data.details);
              
                setTemplateData({
                  ...templateData,
                  template_details : data.details,
                  selected : collection,
                  collapsed : [],
                  frontMatter : data.frontMatter,
                  lines : data.lines,
                  name : templateName,
                  frontMatterOptions : frontMatterOptions,
                });     
              }          
            });
          }
          else {
            res.json().then((data) => {        
              if (data.message) {
                alert(data.message);
              }
              else {
                alert('Error accessing templates.'); 
              }    
            })      
          }
        })
        .catch((err) => {
          console.error(err);
          alert('Error accessing templates.');
        });   
}

export function variablePOST(topData, variableData, setVariableData, data, next){
    const params = {
          ...data,
          userId     : topData.userId,
          email      : topData.userEmail,
          token      : topData.token,   
          Key        : topData.AccessKeyId,
          Secret     : topData.SecretAccessKey,
          nonce      : topData.nonce,
          groupName  : topData.groupName,
        };
      const url = topData.endpoint + '/variable';
      callToBackend(url, params, topData.token, {}, 'POST')  
      .then((res) => {
        if (401 === res.status) { 
          res.json().then((data) => {
  
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Error saving variables.'); 
            }        
          })
        }
        else if (200 === res.status) {
          res.json().then((ret) => {
            setVariableData({
              ...variableData,
              varSets : { ...variableData.varSets, [ret.info.id] : ret.info },
              newVariable : ret.info,
            });    
                                   
            next(ret.tag, ret.info);          
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
}

export function templateGETfour(topData, setTopData, workspaceData, setWorkspaceData, setTemplateData, emptyState) {
    const params = {
        email: topData.userEmail,
        Key: topData.AccessKeyId,
        Secret: topData.SecretAccessKey,
        nonce: topData.nonce,
        groupName: topData.groupName,
        userId: topData.userId,
      };
    const url = topData.endpoint + '/template';
    callToBackend(url, params, topData.token, {}, 'GET')

    .then((res) => {
      if (401 === res.status) {
        res.json().then((tempsData) => {
        if (tempsData.message) {
            setTopData({
              ...topData,
              loginWarning: tempsData.message,
            });
          } else {
          setTopData({
            ...topData,
            loginWarning: "Error retrieving workspace templates.",
          });
      }
      });
    } else if (200 === res.status) {
        res.json().then((tempsData) => {
          setWorkspaceData({
            ...workspaceData, 
            liveTemps: tempsData['templates'],
            draftTemps: tempsData['drafts'],
          });
          alert("Template save successful!");
          // refreshOpenTemplate();
          // Redirecting to the Template List until a better process 
          // for loading the changes after a save is set-up
          setTemplateData(emptyState);
          setTopData({
            ...topData,
            loading: false,
            isSaving: false,
            loginWarning: "",
            page: "templates"
          });
        });
      }
    })
    .catch((err) => {
      console.log(err);
      alert('Error retrieving updated template lists.');
      setTopData({...topData, loading : false,});
    });
}

export function sectionPOST(topData, setTopData, variable_sets, s, updated, list, process_next_leaf){
    const params = {
          email : topData.userEmail,
          token : topData.token,
          Key : topData.AccessKeyId,
          Secret : topData.SecretAccessKey,
          nonce : topData.nonce,
          groupName : topData.groupName,
          section : s,
          vsets : variable_sets,
        };
      const url = topData.endpoint + '/section';
      callToBackend(url, params, topData.token, {}, 'POST')  
      .then((response) => {
        if (200 === response.status) {
          s.changed = false;
          response.json().then((data) => {
            if ('top' === s.parent) {
              s.id = data.id;
              s.version = data.version;
              s.updated_at = data.updated_at;
              updated.push(s); 
            }
            else {
              list.forEach((p) => {
                if (p.id === s.parent) {
                  const children = p.children.reduce((prev, curr) => {
                    if (curr !== s.id) {
                      prev.push(curr);
                    }
                    return prev;
                  }, []);
                  p.children = children;
                  
                  p.changed = true;
                  p.sub_sections.forEach((ss) => {
                    if (ss.id === s.id) {
                      ss.version = data.version;
                      ss.id = data.id;
                    }
                  });                    
                }
              });
            }
          
            const decrementedList = list.reduce((prev, curr) => {
              if (curr.id !== s.id) {
                prev.push(curr);
              }
              return prev;
            }, []);
          
            process_next_leaf(decrementedList);
          });
        }
        else {
          response.json().then((data) => {

            if (data.message) {
              alert(data.message);
              setTopData({...topData, loading : false,});
            }
            else {
              alert('Error saving template.'); 
              setTopData({...topData, loading : false,});
            }       
          })            
        }
      }); 
}

export function templatePUTtwo(topData, setTopData, templateData, refreshTemplateLists){
    // Call the /template/PUT with no parameters.
    const url = topData.endpoint + "/template";
    const params = {
        email : topData.userEmail,
        token : topData.token,
        template : {
          sections : templateData.template_details,
          lines : templateData.lines,
        },
        id : templateData.template_id,
        draft_id : templateData.draft_id,
        version : templateData.template_version,
        status : 'draft',
        selected : templateData.selected,
        name : templateData.newDraftName,
        description : templateData.newDraftDescription,
        Key : topData.AccessKeyId,
        Secret : topData.SecretAccessKey,
        nonce : topData.nonce,
        groupName : topData.groupName,
        frontMatter : templateData.frontMatter,
      };
      callToBackend(url, params, topData.token, {}, 'PUT')
    .then((response) => {
      if (200 === response.status) {
        response.json().then((data) => {  
          refreshTemplateLists("draft");
        });
      }
      else {
        response.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
            setTopData({...topData, loading : false,});
          }
          else {
            alert("Error saving new draft."); 
            setTopData({...topData, loading : false,});
          }        
        })            
      }
    });
}

export function templatePUT(topData, updated, templateData, status, workspaceData, setWorkspaceData, setTemplateData, setTopData, refreshTemplateLists){
    const params = {
          email : topData.userEmail,
          userId : topData.userId,
          token : topData.token,
          template : {
            sections : updated,
            lines    : templateData.lines,
          },
          id : templateData.template_id,
          version : templateData.template_version,
          selected : templateData.selected,
          draft_id : templateData.draft_id,
          name : templateData.newName,
          description : templateData.description,
          status : status,
          Key : topData.AccessKeyId,
          Secret : topData.SecretAccessKey,
          nonce : topData.nonce,
          groupName : topData.groupName,
          frontMatter : templateData.frontMatter, 
        };
      const url = topData.endpoint + '/template';
      callToBackend(url, params, topData.token, {}, 'PUT')
      .then((response) => {
        if (200 === response.status) {
            response.json().then((data) => { 
              // new -> add new to list
              // version -> update version. 
              
              let templates = workspaceData.liveTemps;
              let draft_id = templateData.draft_id;
              let drafts = workspaceData.draftTemps;
            
              if ('new' === status) {
                templates = [ ...workspaceData.liveTemps, {
                  id: data.id,
                  version: data.version,
                  name: templateData.newName,
                  description: templateData.description,
                  sections: templateData.template_details
                } ];
                
                draft_id = "";
              }
              else if ('version' === status) {
                templates = workspaceData.liveTemps.map((t) => {
                  if (t.id === data.id) {
                    return { ...t, version: data.version, sections: updated, }
                  }
                  else {
                    return t;
                  }
                });
                
                drafts = workspaceData.draftTemps.reduce((prev, curr) => {
                  if (curr.draft_id !== draft_id) {
                    prev.push(curr);
                  }
                  
                  return prev;
                }, []);
                
                draft_id = "";
              }

              setWorkspaceData({
                ...workspaceData,
                liveTemps : templates,
                draftTemps : drafts,
              });

              setTemplateData({
                ...templateData,
                selected : [],
                template_details : [],
                draft : draft_id,
                footer : false,
                updated : {
                  id : templateData.template_id,
                  when : Date.now()
                },
                versionSaving : "",
                branchSaving  : "",
              });  

              refreshTemplateLists(status);
            });      
        }
        else {
          response.json().then((data) => {
  
            if (data.message) {
              alert(data.message);
              setTopData({...topData, loading : false,});
            }
            else {
              alert('Error saving template.');
              setTopData({...topData, loading : false,});
            }        
          })            
        }
      });   
}