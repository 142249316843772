export default function LoadingModal({ progress }) {
    return (
        <div className="modal-overlay">
            <div 
                className="modal-content" 
                style={{ width: '50%' }}
            >
                <h2>Loading Workspace</h2>
                <div className="progress-bar">
                    <div 
                        className="progress-bar-fill" 
                        style={{ width: `${progress}%` }} />
                </div>
            </div>
        </div>
    );
}