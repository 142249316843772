import './App.css';
import { useTopDataStore } from "./TopDataStoreProvider";
import { useTemplateDataStore, emptyState } from "./TemplateDataStoreProvider";
import { useWorkspaceDataStore } from "./WorkspaceDataStoreProvider";
import { useEffect, useState } from "react";
import { Container, Row, Col, Button, Spinner, Form } from 'react-bootstrap';
import { templatePUT, templatePUTtwo, templateGETfour, sectionPOST } from './functions/api-calls';

export default function TemplateSaveChoices() {

  const { templateData, setTemplateData } = useTemplateDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  const { topData, setTopData } = useTopDataStore();

  const [saveOption, setSaveOption] = useState("New draft");

  useEffect(()=> {
    setTopData({
      ...topData,
      loading: false,
      loading2: false,
      loading3: false,
    });
  }, []);
  
  const refreshTemplateLists = (saveType) => {
    templateGETfour(topData, setTopData, workspaceData, setWorkspaceData, setTemplateData, emptyState)
  } 

  /*
  const refreshOpenTemplate = () => {
    // Reload id and title of open template after a save is made
    // TO DO WITH BACKEND HELP: Need to be able to pull the ID from the just saved 
    // template and refresh the setup page to match that information
  }
*/

  // Iterate through each template section to identify what needs to be saved
  const process_sections = (sections, status) => {
    const linearize = (tree, list, parent) => {
      let i = 1;
      tree.forEach((s) => {
        if (!s['id'] || !templateData.selected.includes(s['id'])) {
          return;
        }
        
        if (s['id'].match(/^newSection/)) {
          s['changed'] = true;
        } 
        else {
          s['changed'] = false;
        }
        
        s['parent'] = parent;
        if ('top' === parent) {
          s['order'] = i;
          i++;
        }
        s['children'] = [];
        
        if (s['sub_sections'] && s['sub_sections'].length) {
          const subs = s['sub_sections'].reduce((prev, ss) => {
            if (ss['id'] && templateData.selected.includes(ss['id'])) {
              s['children'].push(ss['id']);
              return [ ...prev, ss ];
            }
            else {
              return prev;
            }
          }, []);
          s['sub_sections'] = subs;
          
          linearize(s['sub_sections'], list, s['id']);
        }
        list.push(s);        
      });
    }
      
    const list = [];

    linearize(sections, list, 'top');
        
    const findPart = (sections, part, id) => {
      return sections.reduce((prev, s) => {
        if (s['id'] === id) {
          return s[part] || "";
        }
        else if(s['sub_sections']) {
          return findPart(s['sub_sections'], part, id) || prev;
        }
        else {
          return prev;
        }
      }, '');
    }
    
    const findSection = (id, sections) => {
      let found = false;
      return sections.reduce((prev, curr) => {
        if (found) {
          return prev;
        }
        else if (curr.id === id) {
          found = true;
          return curr;
        }
        else if (curr.sub_sections && curr.sub_sections.length) {
          const s = findSection(id, curr.sub_sections);
          if (s.id) { return s; }
          else { return prev; }
        }
        else {
          return prev;
        }        
      }, {});
    }
    
    const unalteredDetails = () => {
      return workspaceData.liveTemps.reduce((prev, curr) => {
        if (curr.id === templateData.template_id && curr.version === templateData.template_version) {
          return curr;
        }
        else {
          return prev;
        }
      }, {sections: []});
    }
    
    const differentSubSections = (s) => {
      if (!s.id) { return false; }
    
      const details   = unalteredDetails();
      const unaltered = findSection(s.id, details.sections);
      
      if (!s.sub_sections) { s.sub_sections = []; }
      if (!unaltered.sub_sections) { unaltered.sub_sections = []; }
      
      if (s.sub_sections.length !== unaltered.sub_sections.length) {
        return true;
      }
      else if (0 === s.sub_sections.length) {
        return false;
      }
      else {
        let i = 0;
        return s.sub_sections.reduce((prev, curr) => {
          const same = (curr.id === unaltered.sub_sections[i].id);
          i++;
          if (!same) { 
            return true; 
          }
          else { return prev; }
        }, false);
      }
    }
    
    const different = (a, part, id) => {
      a = a || '';
      
      // need to find the unaltered version, as last downloaded, for comparison
      const details = unalteredDetails();
      
      let b = findPart(details.sections, part, id);
    
      a = a.replace(/^\s+|\s+$/g, '');
      b = b.replace(/^\s+|\s+$/g, '');
      a = a.replace(/\s+/g, ' ');
      b = b.replace(/\s+/g, ' ');
      return (a !== b);
    };
    
    const updated = [];
    
    const process_next_leaf = (list) => {
      const s = list.reduce((prev, curr) => {
        if (prev) { return prev; }
        else {
          if (curr.children && curr.children.length) {
            return prev;
          }
          else {
            if (!curr['changed'] && differentSubSections(curr)) {
              curr['changed'] = true;
            }
            else if ( !curr['changed'] && (different(curr['guidance'], 'guidance', curr.id) || different(curr['html'], 'html', curr.id) || different(curr['name'], 'name', curr.id) ) ) {
              curr['changed'] = true;
            }
            
            return curr;
          }
        }
      }, false);
      
      if (s) {
        if (s['changed']) {
          const variable_sets = [];
          const re = /data-tag="(\w+)\./g;
          let   m  = [];
          while ((m = re.exec(s.html)) !== null) {
            variable_sets.push(m[1]);
          }
          
          if ('version' === status) {
            setTemplateData({
              ...templateData,
              versionSaving : `saving ${s.name} section...`,
              loading2 : true,
            });         
          }
          else {
            setTemplateData({
              ...templateData,
              loading3 : true,
              branchSaving : `saving ${s.name} section...`,
            });         
          }
          
          sectionPOST(topData, setTopData, variable_sets, s, updated, list, process_next_leaf)
                        
        }
        else {
          if ('top' === s.parent) {
            updated.push(s);
          }
          else {
            list.forEach((p) => {
              if (p.id === s.parent) {
                const children = p.children.reduce((prev, curr) => {
                  if (curr !== s.id) {
                    prev.push(curr);
                  }
                  return prev;
                }, []);
                p.children = children;
              }
            });
          }
        
          const decrementedList = list.reduce((prev, curr) => {
            if (curr.id !== s.id) {
              prev.push(curr);
            }
            return prev;
          }, []);
        
          process_next_leaf(decrementedList);
        }           
      }
      else {
        updated.sort((a,b) => {if (a['order'] > b['order']) { return 1; } else { return -1; }});
                  
        if ('version' === status) {
          setTemplateData({
            ...templateData,
            loading2 : true,
            versionSaving : 'final step, saving overall template structure...',
          });         
          
          templatePUT(topData, updated, templateData, status, workspaceData, setWorkspaceData, setTemplateData, setTopData, refreshTemplateLists);
        }
        else {
          setTemplateData({
            ...templateData,
            loading3 : true,
            branchSaving : 'final step, saving overall template structure...',
          });         
        }
      }
    };
        
    process_next_leaf(list);
  };

  // Create a new version for the save
  const saveNew = (event) => {
    event.preventDefault();
    
    if (!templateData.newName) {
      alert('Please indicate the name for the new Template');
      return false;
    }    
    else {
      setTemplateData({
        ...templateData, 
        loading3 : true,
        branchSaving : 'compiling changes and contacting server...',
        name : templateData.newName,
      });

      // Call to function to save each section to backend
      process_sections(templateData.template_details, 'new');
    }
  };
  
  const saveVersion = (event) => {
    event.preventDefault();
    
    setTemplateData({
      ...templateData, 
      loading2 : true,
      versionSaving : 'compiling changes and contacting server...',
    });
    
    process_sections(templateData.template_details, 'version');
  };
  
  // Button action for "Save as Draft" 
  const saveDraft = (event) => {
    event.preventDefault();
    setTemplateData({
      ...templateData,
      loading: true,
    });

    // Use templatePUTtwo instead of fetch
    templatePUTtwo(
      topData,
      setTopData,
      templateData,
      refreshTemplateLists
    );
  };

  const setValue = (event) => {
    setTemplateData({ 
      ...templateData, 
      [event.target.name]: event.target.value });
  };  
  
  useEffect(() => {
    const description = workspaceData.liveTemps.reduce((prev,curr) => {
      if (curr.id === templateData.template_id && parseInt(curr.version) === parseInt(templateData.template_version)) {
        return curr.description;
      }
      else {
        return prev;
      }
    }, "");

    setTemplateData({ 
      ...templateData, 
      description: (templateData.draftDescription || description), 
      draftDescription : (templateData.draftDescription || description),
    });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceData.liveTemps]); 

  const handleSave = (event) => {
    event.preventDefault();
    if (saveOption === "New draft") {
      saveDraft(event);
    } else if (saveOption === "New template") {
      saveNew(event);
    } else if (saveOption === "Updated version") {
      saveVersion(event);
    }
  };

  // Define page layout
  return (
    <Container className="subPage">
      <Row className="subPageRow gx-5">
        <Col md="7">
          <div className="d-flex flex-column gap-3">
            <div className="d-flex gap-3 w-100">
              <div className="d-inline-flex align-items-center gap-1 w-100">
                <Form.Label className="mb-0 text-nowrap">Save as:</Form.Label>
                <Form.Control
                  as="select"
                  value={saveOption}
                  onChange={(e) => setSaveOption(e.target.value)}
                  className="ms-3"
                  style={{ width: 'auto' }}
                >
                  <option>New draft</option>
                  <option>New template</option>
                  <option>Updated version</option>
                </Form.Control>
              </div>
              <Button
                className="dataAddInstance saveTempBtn w-auto"
                onClick={handleSave}            >
                {
                  templateData.loading ? 
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />        
                  :
                  <>Save</>              
                }
              </Button>
            </div>
            <div className="d-flex flex-column gap-0">
              <Form.Control
                type="text"
                placeholder="Title"
                name="newName"
                onChange={setValue}
                className="dataLiveType saveLive"
                value={saveOption === "Updated version" ? "" : templateData.newName}
                disabled={saveOption === "Updated version"}
              />
              <Form.Control
                type="text"
                placeholder="Description"
                name="description"
                onChange={setValue}
                className="dataLiveType saveLive"
                value={saveOption === "Updated version" ? "" : templateData.description}
                disabled={saveOption === "Updated version"}
              />
            </div>
          </div>
        </Col>
        <Col md="4">
          <p className="saveInstructions">
            Draft: Your edited template will appear as a choice
            for editing when you log in, but will not appear for any other users.
          </p>
          <p className="saveInstructions">
           New: Saving as a new template will make your template available for use in a document.
          </p>
          <p className="saveInstructions">
            Version: Will save a new version of the existing template. Once saved, this latest version will appear as the default version of this template for any new documents 
            and for anyone wanting to edit this template. The previous version of the template 
            will continue to exist in the database, and any existing documents will continue to 
            use the version of the template they were created from.
          </p>
        </Col>
      </Row>
      <Row style={{marginBottom: 35}}>
        <Col>
          <div className="welcomeText">{templateData.versionSaving}</div>
        </Col>  
        <Col>
          <div className="welcomeText">{templateData.branchSaving}</div>
        </Col>        
      </Row> 
    </Container>
  );
}