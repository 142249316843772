import AsclepiaLib from "./AsclepiaLib";

export function refreshLocalDocs(workspaceData, setWorkspaceData) {
  
  const companyDocuments = AsclepiaLib.deepCopy(workspaceData.docs); 
  
  // Retaining, but in the process of deleting companyDocuments
  companyDocuments.sort((a, b) => (a.documentName > b.documentName) ? 1 : -1);
  setWorkspaceData({
    ...workspaceData,
    companyDocuments: companyDocuments,
  });
}

export function gotoDocument(event, topData, setTopData, documentData, setDocumentData) {
  event.preventDefault();
  
  setTopData({
    ...topData,
    page: 'documentPageDisambiguate',
  });

  setDocumentData({
    ...documentData,
    documentId: event.target.dataset.id,
  });
}
