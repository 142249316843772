import './App.css';
import { useTopDataStore } from "./TopDataStoreProvider";
import { useTemplateDataStore, emptyState } from "./TemplateDataStoreProvider";
import { Container, Row, Col, Spinner } from 'react-bootstrap';
import { useEffect } from 'react';
import { Plus, Trash } from "./img/Vectors";
import { useWorkspaceDataStore } from './WorkspaceDataStoreProvider';
import { useLoadDataStore } from './LoadDataStoreProvider';
import { openTemplate, deleteTemplate } from './functions/templates';
import { fetchDataForWorkspace } from './functions/workspace';

/**
 * Component to display a list of templates, either drafts or live templates.
 * @param {Object} props - Component properties.
 * @param {boolean} props.draft - Determines if the list is for drafts or live templates.
 */
function SubLists({ draft }) {
  const { topData, setTopData } = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();

  // Select the appropriate templates based on the draft flag
  const templates = draft ? workspaceData.draftTemps : workspaceData.liveTemps;

  if (templates.length) {
    // Sort templates alphabetically by name
    templates.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1);

    return templates.map((item) => {
      // Find the corresponding live template name for a draft
      const templateName = draft ? workspaceData.liveTemps.reduce((prev, curr) => {
        return item.id === curr.id ? curr.name : prev;
      }, "") : '';

      return (
        <div key={item.id + (draft ? '-' + item.draft_id + '.' + item.version : '')} className="docListItem">
          <div className="docTextBlock">
            <a href="#id" onClick={(e) => openTemplate(e, item.id, item.version, draft ? item.draft_id : undefined, item.name, item.description, templateData, setTemplateData, topData, setTopData, draft)}>
              {item.name || (draft ? 'Unnamed Draft' : 'Unnamed Template')}
            </a>&nbsp;
            <span className="docListItemDetail">
              {draft ? 
                (item.version === 0 ? 'Draft, original' : ('Draft, based on version ' + item.version + ' of ' + templateName)) :
                (item.description + ' (version ' + item.version + ')')}
            </span>
          </div>
          <a href="/delete-template" 
            onClick={(e) => deleteTemplate(e, draft ? item.draft_id : item.id, item.name || (draft ? 'Unnamed Draft' : 'Unnamed Template'), item.version, workspaceData, setWorkspaceData, topData, draft)}
            className="trashButtonList">
              <Trash />            
          </a>
        </div>
      );
    });
  } else {
    // Display message if no templates are available
    return (
      <div className="welcomeText">
        No available {draft ? 'drafts' : 'templates'}.
      </div>
    );
  }
}

/**
 * Main component to display the templates page.
 */
export default function TemplatesList() {
  const { topData, setTopData } = useTopDataStore();
  const { templateData, setTemplateData } = useTemplateDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  const { loadData, setLoadData } = useLoadDataStore();

  // Refresh template list on component mount
  useEffect(() => {
    setTemplateData(emptyState);
    setTopData({
      ...topData,
      loading: true,
    });

    fetchDataForWorkspace(topData, workspaceData, setWorkspaceData, setLoadData, ["T"]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Event handler to navigate to the setup module for a new template.
   * @param {Event} event - The click event.
   */
  const gotoSetup = (event) => {
    event.preventDefault();

    setTemplateData({
      ...templateData,
      loading: true,
    });

    // Redirect to the template setup page
    setTopData({
      ...topData,
      page: "templateSetup",
    });
  };

  // Render the page layout
  return (
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">Templates</h2>
      </Row>
      <Row>
        {
          !loadData.isLoading ?
            <>
              <Row key="newTemplate">
                <div className="docListItem" id="newTemplate">
                  <a href="#id" onClick={gotoSetup}>
                    <Plus />Create New Template
                  </a>
                </div>
              </Row>
              <Row>   
                <Col xs={10}>
                  <h4 className="pageLevel2Head">Drafts</h4>
                  <SubLists draft={true} />
                </Col>   
                <Col xs={10}>
                  <h4 className="pageLevel2Head proto">Active Templates</h4>
                  <SubLists draft={false} />
                </Col>    
              </Row> 
            </>
          : <>
              <Spinner
                as="span"
                animation="border"
                size="lg"
                role="status"
                aria-hidden="true"
              /><br/><br />
              <p className="loadWarning">
                Loading workspace templates.              
              </p>
            </>                
        }
      </Row>
    </Container>
  );
}