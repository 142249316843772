import './App.css';
import { Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useTemplateDataStore, emptyState } from './TemplateDataStoreProvider';
import { Plus, Profile, LogoIpsum } from "./img/Vectors";
import Cookies from 'js-cookie';
import { useWorkspaceDataStore, refreshState } from './WorkspaceDataStoreProvider';

function DocumentsNav(){
  
  const { topData, setTopData } = useTopDataStore();

  const newDocument = (event) => {
      event.preventDefault();
      setTopData({
        ...topData,
        id : "",
        last_saved : 0,
        template_id : "",
        page : 'createDocOutlet',
      });
    };
   
    const documentList = (event) => {
      event.preventDefault();
      setTopData({
        ...topData,
        id : "",
        last_saved : 0,
        page : 'documentList',
      });
    }

    if (topData.permissions) {
      if (['admin', 'superuser', 'manager'].includes(topData.permissions)) {
        return (
            <NavDropdown title="Documents" id="documents" className="hNavText" >
              <NavDropdown.Item className="dropDownItem" onClick={documentList} href="#action/4.1">
                All Documents
              </NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item className="dropDownItem" onClick={newDocument} href="#action/4.2">
                <Plus />Create New Document
              </NavDropdown.Item>
            </NavDropdown>
        );
      }
      else {
        return "";
      }
    }
    else {
      return "";
    }
}

function TemplatesNav(){
  const { topData, setTopData } = useTopDataStore(); 
  const { templateData, setTemplateData } = useTemplateDataStore();
    
  const gotoSetup = (event) => {
    event.preventDefault();
    setTemplateData(emptyState);
    setTopData({
      ...topData,
      page : "templateSetup",
    });        
           
  };

  const templates = (event) => {
    event.preventDefault();
    setTopData({
      ...topData,
      id : "", // TO DO: Eliminate this
      last_saved : 0, // TO DO: Eliminate this
      page : 'templates',
    });
  }
    
  return (
    <NavDropdown title="Templates" id="templates" className="hNavText" disabled={topData.loadingEnvironment}>
      <NavDropdown.Item className="dropDownItem" onClick={templates} href="#action/3.1" disabled={topData.loadingEnvironment}>
        All Templates
      </NavDropdown.Item>
        <NavDropdown.Divider />
      <NavDropdown.Item className="dropDownItem" onClick={gotoSetup} href="#action/3.2" disabled={topData.loadingEnvironment}>
        <Plus />Create New Template        
      </NavDropdown.Item>
    </NavDropdown>
  );
}

export default function HorizNav(){
  const { topData, setTopData } = useTopDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  
  const logout = (event) => {
    Cookies.remove('user', { path: '/', secure: true, sameSite: 'strict'  }); // Includethe values used when setting a cookie to delete
    Cookies.remove('cc_cookie', { path: '/', sameSite: 'lax' }); // https://github.com/js-cookie/js-cookie
    setWorkspaceData(refreshState);
    setTopData(initialState);
    // Reload page to retrigger cookie consent.
    window.location.reload(true);
  }
  
  const profile = (event) => {
    event.preventDefault();
    setTopData({ 
      ...topData, 
      page : 'profile', 
      userPassword : '', 
      newPassword : '', 
      newEmail: topData.userEmail 
    });
  };

  const dashboard = (event) => {
    event.preventDefault();
    setTopData({ 
      ...topData, 
      page: 'welcome', 
    });
  };

  const man_variables = (event) => {
    event.preventDefault();
    setTopData({ 
      ...topData, 
      page: 'variables', 
    });
  };

  return (
    <Navbar className="hNavMain" sticky="top">
      <Navbar.Brand href="#home" className="hNavLogo">
        <LogoIpsum /> <span style={{fontSize:"1rem"}}>powered by <span className="kinetikaOrange">kinetika</span></span>
      </Navbar.Brand>
        <Navbar.Toggle />
      <Navbar.Collapse className="justify-content-end">
        <Navbar.Text className="hNavText" id="hNavDash" onClick={dashboard} disabled={topData.loadingEnvironment}>
          <a href="#dash">Dashboard</a>
        </Navbar.Text>
        <TemplatesNav />
        <DocumentsNav />
        <Navbar.Text className="hNavText" id="hNavVar" onClick={man_variables} disabled={topData.loadingEnvironment}>
          <a href="#variables">Variables</a>
        </Navbar.Text>
        <Navbar.Text className="hNavText" id="hNavLogout" onClick={logout}>
          <a href="#variables">Logout</a>
        </Navbar.Text>
        <Nav.Link href="#Profile" className="hNavProfile" onClick={profile}> 
          <Profile />
        </Nav.Link>
      </Navbar.Collapse>
    </Navbar>
  );
}