import { templateDELETE } from './api-calls';

export function deleteTemplate(e, id, name, version, workspaceData, setWorkspaceData, topData, draft = false) {
  e.preventDefault();
  
  const confirmMessage = draft 
    ? `Permanently delete template draft ${name}?`
    : `Permanently delete template ${name} and all derived draft templates and documents?`;

  if (window.confirm(confirmMessage)) {
    const liveTemps = draft ? workspaceData.liveTemps : workspaceData.liveTemps.filter(curr => curr.id !== id);
    const draftTemps = workspaceData.draftTemps.filter(curr => draft ? curr.draft_id !== id : curr.id !== id);
    
    setWorkspaceData({
      ...workspaceData,
      liveTemps: liveTemps,
      draftTemps: draftTemps,
    });
          
    templateDELETE(topData, draft, id, version, name);

  }
}

export function openTemplate(event, id, version, draftId, name, description, templateData, setTemplateData, topData, setTopData, draft = false) {
  event.preventDefault();

  setTemplateData({
    ...templateData,
    footer: true,
    template_id: id,
    template_version: version,
    draft_id: draft ? draftId : "",
    dirty: 0,
    draftName: draft ? name : "",
    description: draft ? description : "",
    name: draft ? undefined : name,
    templateType: draft ? "draft" : "live",
  });

  setTopData({
    ...topData,
    loading: false,
    isSaving: false,
    loginWarning: "",
    page: "templateSetup",
  });
}
