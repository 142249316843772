import { createContext, useState, useContext } from "react";

/*
  Template Data Store holds data about a template currently edited or being accessed
  for use in setting up a document. It does not contain any variable data.
  The list of templates accessible in the workspace is stored in the Workspace Data.
*/
const TemplateDataStore = createContext();
export const useTemplateDataStore = () => useContext(TemplateDataStore);

export const emptyState = {
  name : "",
  template_details : [],
  collapse: [],
  lines : [],
  frontMatterOptions : ['titlePage', 'tableOfContents', 'synopsis', 'versions', 'abbreviations', 
    'schematic', 'signaturePages', 'complianceStatement', 'scheduleOfEvents'],
  footer : false, 
  existing : [],
  selected : [],
  frontMatter : ['titlePage', 'tableOfContents', 'synopsis', 'versions', 'abbreviations', 
    'schematic', 'signaturePages', 'complianceStatement', 'scheduleOfEvents'],
  templateType : "",
  liveTemps : {},
  draftTemps: {},
  template_id: "",
  draft_id: "",
  template_version: 0,
  newDraftName: "",
  newDraftDescription: "",

};

export default function TemplateDataStoreProvider({children}) {
  const [templateData, setTemplateData] = useState(emptyState);
  
  return (
    <TemplateDataStore.Provider value={{templateData, setTemplateData}}>
      {children}
    </TemplateDataStore.Provider>
  );  
}