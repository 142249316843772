import './App.css';
import { useTopDataStore } from "./TopDataStoreProvider";
import { useDocumentDataStore, emptyDocument } from "./DocumentDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Card from 'react-bootstrap/Card';

import { permissionGET, documentGETtwo } from './functions/api-calls';

function DocumentList() {
  const { topData, setTopData } = useTopDataStore(); 
  const { documentData, setDocumentData } = useDocumentDataStore();
  
  const manageDocument = (event) => {
    event.preventDefault();
    setDocumentData({ ...documentData, loading : true });
    
    const documentId = event.target.dataset.id;
    permissionGET(topData, setTopData, documentData, setDocumentData, documentId);
  };
  
  return documentData.allDocuments.map((document) => {
    return (
      <Card key={document.id} style={{"marginBottom":"10px"}}>
        <Card.Body>
          <Card.Title>{document.documentName}</Card.Title>
          <Card.Text as="div">
            <ul>
              <li>
                <a href="/status" onClick={manageDocument} data-id={document.id}>
                  Manage the team for or status of this document
                </a>
              </li>
              <li>View the document in its current form</li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  });
}

function TeamList() {
  const { topData } = useTopDataStore();
  
  return Object.keys(topData.team).map((person_id) => {

    let permission = "";
    switch (topData.team[person_id].permission) {
      case 'admin':
        permission = 'Company Administrator: Can add/remove people at all permission levels, manage documents, and contribute to documents';
        break;
      case 'superuser':
        permission = 'Company Document Manager: Can add/remove document contributors, manage documents, and contribute to documents';
        break;
      case 'manager':
        permission = 'Document Manager: Can add/remove document contributors and manage specific documents as assigned, and contribute to documents';
        break;
      case 'contributor':
        permission = 'Contributor: Can contribute to specific documents as assigned';
        break;
        
      default:
        permission = 'No Permissions';
    }
    
    return (
      <Card key={person_id} style={{"marginBottom":"10px"}}>
        <Card.Body>
          <Card.Title>{topData.team[person_id].name}</Card.Title>
          <Card.Subtitle>{permission}</Card.Subtitle>
          <Card.Text as="div">
            <ul>
              <li>Manage permissions</li>
              <li>Manage document assignments</li>
            </ul>
          </Card.Text>
        </Card.Body>
      </Card>
    );
  });
}

function DocumentData() {
  const { topData, setTopData } = useTopDataStore(); 
  const { documentData, setDocumentData } = useDocumentDataStore();

  if ( "undefined" !== typeof(documentData.allDocuments) ) {
    return (
      <>
        <h2>Documents</h2>
        <DocumentList />
        <br />
        <h2>Team</h2>
        <TeamList />
      </>
    );
  }
  else {
    documentGETtwo(topData, setTopData, documentData, setDocumentData);
  
    return (
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />                        
    );
  }
}

function ManageDocuments() {
  const { topData, setTopData } = useTopDataStore(); 
  const { setDocumentData } = useDocumentDataStore();

  const newDocument = (event) => {
    event.preventDefault();
    setDocumentData(emptyDocument);
    setTopData({
      ...topData,
      id : "",
      last_saved : 0,
      template_id : "",
      page : 'createDocOutlet',
    });
  };

  return (
    <Container className="p-3">
      <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
        <h1 className="h2">You have permission to manage all documents</h1>
        <div className="btn-toolbar mb-2 mb-md-0">
          <div className="btn-group me-2">
            <Button onClick={newDocument} variant="outline-primary">
              Start a New Document
            </Button>
          </div>
        </div>
      </div>
      <DocumentData />   
    </Container>
  );
}

export default ManageDocuments;