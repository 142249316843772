import './App.css';
import { useTopDataStore } from "./TopDataStoreProvider";
import { useDocumentDataStore } from "./DocumentDataStoreProvider";
import { Container, Row, Col  } from 'react-bootstrap';

import LoadingDoc from "./LoadingDoc";
import EditDoc from './EditDoc';
import DocumentVarValues from "./DocumentVarValues";
import NavSide from "./components/NavSide";

function OutletSwitch() {
  const { topData } = useTopDataStore();
  
    switch (topData.page) {    
      case "documentPageDisambiguate":
        return (<LoadingDoc />);
      case "edit":
        return (<EditDoc />);
      case "studyInformation":
      case "objectives":
      case "studyDesign":
      case "patientPopulation":
      case "_variables":
      case "templateTitleLayout":
      case "templateTitleEdit":
        return(<DocumentVarValues />);
      default:
        return (<EditDoc />);
    }
}

export default function DocumentOutletId(){

  const { documentData } = useDocumentDataStore();

  // Define items to appear in the page's side navigation
  const sideNavVars = [
    {
      title: "Data Entry",
      page: "studyInformation"
    }, 
    {
      title: "Study Information",
      page: "studyInformation",
      sub: "level2"
    },
    {
      title: "Objectives",
      page: "objectives",
      sub: "level2"
    },
    {
      title: "Study Design",
      page: "studyDesign",
      sub: "level2"
    },
    {
      title: "Patient Population",
      page: "patientPopulation",
      sub: "level2"
    },
    {
      title: "Variables",
      page: "_variables",
      sub: "level2"
    },
    {
      title: "Text Editor",
      page: "edit"
    }      
  ];
  
  // Page layout
  return(
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">
          {documentData.documentName || "New Document"}
        </h2>
      </Row>
      <Row> 
        <Col xs={2} className="sectionSidebar">
          <NavSide elements={sideNavVars} />
        </Col>
        <Col>
          <OutletSwitch />           
        </Col>
      </Row>
    </Container>
  );
}