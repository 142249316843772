import { Container, Row, Col } from "react-bootstrap";
import { useTopDataStore } from "./TopDataStoreProvider";
import NavSide from "./components/NavSide";
import WorkspaceVariables from "./WorkspaceVariables";

function OutletSwitch() {
  const { topData } = useTopDataStore();

  switch (topData.page) {         
    case 'variables-request':
      return (<VariablesRequest />);
    case 'variablesDash':
      return (<VariablesDash />);
    case 'variables':
    case 'variables-all':
    case 'variables-category':
    default:
      return (<VariablesEditor />);
  }
}

function VariablesRequest(){
  return(
    <>
      Future functionality for non-admin to request changes to variables from admin.
    </>
  );
}

function VariablesDash(){
  return(
    <>
      Future functionality for users to review recent changes to the variables.
      Admin users will see outstanding requests on this page as well.
      This should be the default page for future variable module.
    </>
  );
}

function VariablesEditor(){
  return(<WorkspaceVariables />
  );
}

export default function VariableOutletId(){
  
  // Define Items to appear the page's side navigation
  const sideNavVars = [
    {
      title: "Dashboard",
      page: "variables"
    },
    {
      title: "All Variables",
      page: "variables"
    },
      {
        title: "Patient Population",
        page: "variables",
        sub: "level2",
      },
      {
        title: "Characteristics",
        page: "variables",
        sub: "level2",
      },
    {
      title: "Request Changes",
      page: "variables"
    }
  ];

  return(
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">
          Workspace Variables
        </h2>
      </Row>
      <Row> 
        <Col xs={2} className="sectionSidebar">
          <NavSide elements={sideNavVars} />
        </Col>
        <Col>
          <OutletSwitch />           
        </Col>
      </Row>
    </Container>
  );
}