import './App.css';
import { useEffect, useState } from "react";
import { useTopDataStore, initialState } from "./TopDataStoreProvider";
import { useDocumentDataStore, emptyDocument } from "./DocumentDataStoreProvider";
import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
import Form from 'react-bootstrap/Form';
import { callToBackend } from './functions/fetch';

function ManageSpecificDocument() {
  const { documentData, setDocumentData } = useDocumentDataStore();
  const { topData, setTopData }           = useTopDataStore();
  
  const addContributor = (event) => {
    event.preventDefault();
    const buttonLoading = {[event.target.dataset.id] : true};
    setDocumentData({ ...documentData, 'buttonLoading' : buttonLoading });
    
    const url = topData.endpoint + '/permission';
    const params = {
        email      : topData.userEmail,
        token      : topData.token,
        userId     : topData.userId,
        documentId : documentData.documentId,
        permission : event.target.dataset.permission,
        targetId   : event.target.dataset.id,
        Key        : topData.AccessKeyId,
        Secret     : topData.SecretAccessKey,
        nonce      : topData.nonce,
        groupName  : topData.groupName,
      };
    callToBackend(url, params, topData.token, {}, 'PUT')
    .then((res) => {
      if (200 === res.status) {
        res.json().then((resData) => {
          const params = {
            email: topData.userEmail,
            userId: topData.userId,
            documentId: documentData.documentId,
            Key: topData.AccessKeyId,
            Secret: topData.SecretAccessKey,
            nonce: topData.nonce,
            groupName: topData.groupName,
          };
          const url = topData.endpoint + '/permission';
          callToBackend(url, params, topData.token, {}, 'GET')
          .then((res) => {
            if (200 === res.status) {
              res.json().then((data) => {
        
                const documentPermissions = documentData.documentPermissions ? documentData.documentPermissions : {};
                documentPermissions[documentData.documentId] = {
                  admin       : data.admin,
                  contributor : data.contributor,
                  manager     : data.manager,
                  superuser   : data.superuser,
                };
                      
                setDocumentData({ 
                  ...documentData, 
                  'documentPermissions' : documentPermissions, 
                  buttonLoading : {},
                });
                  
                setTopData({
                  ...topData,
                  permissions: resData['permissions'],
                });
              })
            }
            else {
              res.json().then((data) => {
        
                if (data.message) {
                  alert(data.message);
                }
                else {
                  alert('Your login has expired'); 
                }
          
                setTopData(initialState);          
              })      
            }      
          })
          .catch((err) => {
            console.error(err);
            alert('error');
            setDocumentData({ ...documentData, loading : false });
          });
        });
      }
      else {
        res.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }
          
          setTopData(initialState);          
        })      
      }
    })
    .catch((err) => {
      console.error(err);
      setDocumentData({ ...documentData, buttonLoading : {} });
      alert('error');
    });
    
  };
  
  const invite = (event) => {
    event.preventDefault();
    const buttonLoading = { [event.target.dataset.id] : true};
    setDocumentData({ ...documentData, 'buttonLoading' : buttonLoading });
    const targetEmail = event.target.dataset.permission + 'Email';
    const targetName  = event.target.dataset.permission + 'Name';
    
    const params = {
        email      : topData.userEmail,
        token      : topData.token,
        documentId : documentData.documentId,
        invitee    : documentData[targetEmail],
        permission : event.target.dataset.permission,
        invname    : documentData[targetName],
        Key        : topData.AccessKeyId,
        Secret     : topData.SecretAccessKey,
        nonce      : topData.nonce,
        groupName  : topData.groupName,
        urlhost    : window.location.host,
      };
    const url = topData.endpoint + '/permission';
    callToBackend(url, params, topData.token, {}, 'POST')
    .then((res) => { 
      setDocumentData({ ...documentData, buttonLoading : {} }); 
      // TODO: update permissions locally, refresh page. 
    })
    .catch((err) => {
      console.error(err);
      alert('error');
      setDocumentData({ ...documentData, buttonLoading : {} });
    });
  };
  
  const demoteManager = (event) => {
    event.preventDefault();
    const buttonLoading = { [event.target.dataset.id] : true};
    setDocumentData({ ...documentData, 'buttonLoading' : buttonLoading });
    const target        = event.target.dataset.id.replace(/^demote/,'');
   
    const params = {
        email      : topData.userEmail,
        token      : topData.token,
        targetId   : target,
        Key        : topData.AccessKeyId,
        Secret     : topData.SecretAccessKey,
        nonce      : topData.nonce,
        groupName  : topData.groupName,
      };
    const url = topData.endpoint + '/permission';
    callToBackend(url, params, topData.token, {}, 'PUT')
    .then((res) => {
      if (200 === res.status) {
        
        res.json().then((resData) => {
          const params = {
            email: topData.userEmail,
            userId: topData.userId,
            documentId: documentData.documentId,
            Key: topData.AccessKeyId,
            Secret: topData.SecretAccessKey,
            nonce: topData.nonce,
            groupName: topData.groupName,
          };
          const url = topData.endpoint + '/permission';
          callToBackend(url, params, topData.token, {}, 'DGETLETE')      
          .then((res) => {
            if (200 === res.status) {

              res.json().then((data) => {
        
                const documentPermissions = documentData.documentPermissions ? documentData.documentPermissions : {};
                documentPermissions[documentData.documentId] = {
                  admin       : data.admin,
                  contributor : data.contributor,
                  manager     : data.manager,
                  superuser   : data.superuser,
                };
        
                setDocumentData({ 
                  ...documentData, 
                  'documentPermissions' : documentPermissions, 
                  buttonLoading : {},
                });
                
                setTopData({
                  ...topData,
                  permissions: resData['permissions'],
                });
              })
            }
            else {
              res.json().then((data) => {
        
                if (data.message) {
                  alert(data.message);
                }
                else {
                  alert('Your login has expired'); 
                }
          
                setTopData(initialState);          
              })      
            }      
          })
          .catch((err) => {
            console.error(err);
            alert('error');
            setDocumentData({ ...documentData, loading : false });
          });
        });
      }
      else {
        res.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }
          
          setTopData(initialState);          
        })      
      }
    })
    .catch((err) => {
      console.error(err);
      setDocumentData({ ...documentData, buttonLoading : {} });
      alert('error');
    });
  };
  
  const promoteContributor = (event) => {
    event.preventDefault();
    const buttonLoading = { [event.target.dataset.id] : true};
    setDocumentData({ ...documentData, 'buttonLoading' : buttonLoading });
    const target        = event.target.dataset.id.replace(/^promote/,'');
    
    const params = {
        email      : topData.userEmail,
        token      : topData.token,
        target_id  : target,
        Key        : topData.AccessKeyId,
        Secret     : topData.SecretAccessKey,
        nonce      : topData.nonce,
        groupName  : topData.groupName,
      };
    const url = topData.endpoint + '/permission';
    callToBackend(url, params, topData.token, {}, 'PUT')
    .then((res) => {
      if (200 === res.status) {

        res.json().then((resData) => {
          const params = {
            email: topData.userEmail,
            token: topData.token,
            documentId: documentData.documentId,
            Key: topData.AccessKeyId,
            Secret: topData.SecretAccessKey,
            nonce: topData.nonce,
            groupName: topData.groupName,
            userId: topData.userId,
          };
          const url = topData.endpoint + '/permission';
          callToBackend(url, params, topData.token, {}, 'GET')
          .then((res) => {
            if (200 === res.status) {
              res.json().then((data) => {
        
                const documentPermissions = documentData.documentPermissions ? documentData.documentPermissions : {};
                documentPermissions[documentData.documentId] = {
                  admin       : data.admin,
                  contributor : data.contributor,
                  manager     : data.manager,
                  superuser   : data.superuser,
                };
        
                setDocumentData({ 
                  ...documentData, 
                  'documentPermissions' : documentPermissions, 
                  buttonLoading : {},
                });
                
                setTopData({
                  ...topData,
                  permissions: resData['permissions'],
                });
              })
            }
            else {
              res.json().then((data) => {
        
                if (data.message) {
                  alert(data.message);
                }
                else {
                  alert('Your login has expired'); 
                }
          
                setTopData(initialState);          
              })      
            }      
          })
          .catch((err) => {
            console.error(err);
            alert('error');
            setDocumentData({ ...documentData, loading : false });
          });
        });
      }
      else {
        res.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }
          
          setTopData(initialState);          
        })      
      }
    })
    .catch((err) => {
      console.error(err);
      setDocumentData({ ...documentData, buttonLoading : {} });
      alert('error');
    });
  };
  
  const removeContributor = (event) => {
    event.preventDefault();
    const key           = 'r' + event.target.dataset.id;
    const buttonLoading = { [key] : true};
    setDocumentData({ ...documentData, 'buttonLoading' : buttonLoading });
    
    const params = {
        email      : topData.userEmail,
        token      : topData.token,
        userId     : topData.userId,
        documentId : documentData.documentId,
        targetId   : event.target.dataset.id,
        Key        : topData.AccessKeyId,
        Secret     : topData.SecretAccessKey,
        nonce      : topData.nonce,
        groupName  : topData.groupName,
      };
    const url = topData.endpoint + '/permission';
    callToBackend(url, params, topData.token, {}, 'DELETE')
    .then((res) => {
      if (200 === res.status) {
      
        res.json().then((resData) => {
          const url = topData.endpoint + '/permission';
          const params = {
            email: topData.userEmail,
            token: topData.token,
            documentId: documentData.documentId,
            Key: topData.AccessKeyId,
            Secret: topData.SecretAccessKey,
            nonce: topData.nonce,
            groupName: topData.groupName,
            userId: topData.userId,
          };
          callToBackend(url, params, topData.token, {}, 'GET')
          .then((res) => {
            if (200 === res.status) {
              res.json().then((data) => {
        
                const documentPermissions = documentData.documentPermissions ? documentData.documentPermissions : {};
                documentPermissions[documentData.documentId] = {
                  admin       : data.admin,
                  contributor : data.contributor,
                  manager     : data.manager,
                  superuser   : data.superuser,
                };
        
                setDocumentData({ 
                  ...documentData, 
                  'documentPermissions' : documentPermissions, 
                  buttonLoading : {},
                });
                
                setTopData({
                  ...topData,
                  permissions: resData['permissions'],
                });
              })
            }
            else {
              res.json().then((data) => {
        
                if (data.message) {
                  alert(data.message);
                }
                else {
                  alert('Your login has expired'); 
                }
          
                setTopData(initialState);          
              })      
            }      
          })
          .catch((err) => {
            console.error(err);
            alert('error');
            setDocumentData({ ...documentData, loading : false });
          });
        
        });
      
      }
      else {
        res.json().then((data) => {
        
          if (data.message) {
            alert(data.message);
          }
          else {
            alert('Your login has expired'); 
          }
          
          setTopData(initialState);          
        })      
      }
    })
    .catch((err) => {
      console.error(err);
      setDocumentData({ ...documentData, buttonLoading : {} });
      alert('error');
    });
  };
  
  
  const [fetching, setFetching] = useState(false);
  useEffect(() => {
    if (!fetching) {
      setFetching(true);
      const params = {
        email: topData.userEmail,
        token: topData.token,
        documentId: topData.document_id,
        Key: topData.AccessKeyId,
        Secret: topData.SecretAccessKey,
        nonce: topData.nonce,
        groupName: topData.groupName,
        userId: topData.userId,
      };
      const url = topData.endpoint + '/permission';
      callToBackend(url, params, topData.token, {}, 'GET')
      .then((res) => {
        if (200 === res.status) {
          res.json().then((data) => {
      
            const documentPermissions = documentData.documentPermissions ? documentData.documentPermissions : {};
            documentPermissions[topData.document_id] = {
              admin       : data.admin,
              contributor : data.contributor,
              manager     : data.manager,
              superuser   : data.superuser,
            };
                  
            setDocumentData({ 
              ...documentData, 
              'documentId' : topData.document_id, 
              'documentPermissions' : documentPermissions, 
              loadingProj : {},
            });
            
            setFetching(false);
          })
        }
        else {
          res.json().then((data) => {
      
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }
        
            setTopData(initialState);          
          })      
        }      
      })
      .catch((err) => {
        alert('error');
        setDocumentData({ ...documentData, loadingProj : "", });
      });    
    } 
  }, [topData.document_id]);
  
  if (documentData.documentPermissions && documentData.documentPermissions[topData.document_id]) {
    let companyId       = "";
    let documentHeading = "";
    let documentName    = "";
    documentHeading = topData.existing.reduce((prev, curr) => {
      if (curr.documentId === documentData.documentId) {
        documentName = curr.documentName; 
        return `${curr.documentName} v.${curr.version} (Template: ${curr.template_name} v.${curr.template_version})`;
      }
      else {
        return prev;
      }
    }, "");
  
    if (!companyId && documentData.allDocuments) {
      documentHeading = documentData.allDocuments.reduce((prev, curr) => {
        if (curr.id === documentData.documentId) {
          return `${curr.documentName} v.${curr.version} (Template: ${curr.template_name} v.${curr.template_version})`;
        }
        else {
          return prev;
        }
      }, "");  
    }
  
    const higherPermissions = [];
    const contributorIds = documentData.documentPermissions[documentData.documentId].contributor.map((c) => {
      return c.id;
    });
  
    let admins = [];
    if (companyId && 'admin' === topData.permissions[companyId]['permission']) {
      admins = documentData.documentPermissions[documentData.documentId].admin.map((admin) => {
        higherPermissions.push(admin.id);
        return <tr key={'admin' + admin.id}>
          <th>Admin User:</th>
          <td>{admin.name}</td>
          {
            contributorIds.includes(admin.id) 
            ? <td colSpan="2">&nbsp;</td>
            : <td colSpan="2">
                <Button variant="secondary" size="sm" onClick={addContributor} data-id={admin.id} data-permission="contributor">
                  {
                    documentData.buttonLoading[admin.id] ?       
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> :
                    <>Add as Contributor</>                    
                  }
                </Button>
              </td>
          }
        </tr>
      });
    }
    else {
      const adminUsers = documentData.documentPermissions[documentData.documentId].admin.map((admin) => {
        higherPermissions.push(admin.id);
        return (
          <li key={'admin' + admin.id}>{admin.name}</li>
        );    
      });
  
      admins = <tr>
        <th>Admin Users</th>
        <td><ul>{adminUsers}</ul></td>
        <td colSpan="2">&nbsp;</td>
      </tr>
    }
  
    let superUsers = [];
    if (companyId && ['admin', 'superuser'].includes(topData.permissions[companyId]['permission'])) {
      superUsers = documentData.documentPermissions[documentData.documentId].superuser.map((superuser) => {
        higherPermissions.push(superuser.id);
        return <tr key={'s' + superuser.id}>
          <th>Companywide Document Manager</th>
          <td>{superuser.name}</td>
          {
            contributorIds.includes(superuser.id)
            ? <td colSpan="2">&nbsp;</td>
            : <>
                <td colSpan="2">
                  <Button variant="secondary" size="sm" onClick={addContributor} data-id={superuser.id} data-permission="contributor">
                    {
                      documentData.buttonLoading[superuser.id] ?       
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> :
                      <>Add as Contributor</>                    
                    }
                  </Button>
                </td>
              </>
          }
        </tr>;
      });
    }
    else {
      const su = documentData.documentPermissions[documentData.documentId].superuser.map((superuser) => {
        higherPermissions.push(superuser.id);
        return (
          <li key={'s' + superuser.id}>{superuser.name}</li>
        );        
      });

      superUsers = <tr>
        <th>Companywide Document Manager</th>
        <td><ul>{su}</ul></td>
        <td colSpan="2">&nbsp;</td>
      </tr>
    }  
  
    const formAction = (event) => {
      const key = event.target.getAttribute('name');
      const val = event.target.value;
      setDocumentData({ ...documentData, [key]: val });
    };      
  
    let managers   = [];
    let myDocument = false;
    myDocument = topData.existing.reduce((prev, curr) => {
      if (curr.documentId === documentData.documentId) { return true; }
      else { return prev; }
    }, false);  
        
    if (companyId && (['admin', 'superuser'].includes(topData.permissions[companyId]['permission'] || ('manager' === topData.permissions[companyId]['permission'] && myDocument)))) {    

      managers = documentData.documentPermissions[documentData.documentId].manager.map((manager) => {
        return (
          <tr key={'m' + manager.id}>
            <th>Document-Specific Manager:</th>
            <td>{manager.name}</td>
            {
              higherPermissions.includes(manager.id) ? <td colSpan="2">&nbsp;</td>
              : (
                <>
                  <td>
                    <Button variant="danger" size="sm" onClick={removeContributor} data-id={manager.id}>
                      {
                        documentData.buttonLoading['r' + manager.id] ?       
                        <Spinner
                          as="span"
                          animation="border"
                          size="sm"
                          role="status"
                          aria-hidden="true"
                        /> :
                        <>Remove from Document</>                    
                      }                    
                    </Button>
                  </td>
                  <td>
                    <Button variant="warning" size="sm" onClick={demoteManager} data-id={'demote' + manager.id}>
                    {
                      documentData.buttonLoading['demote' + manager.id] ?       
                      <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      /> :
                      <>Demote to Contributor</>                    
                    }                                
                    </Button>
                  </td>            
                </>
              )
            }          
          </tr>     
        );
      });
    
      managers.push(
        <tr key={'newManager'}>
          <th>Add New Document-Specific Manager:</th>
          <td><Form.Control type="text" placeholder="Person's Name" onChange={formAction} name="managerName" value={documentData.managerName} /></td>
          <td><Form.Control type="text" placeholder="Person's Email" onChange={formAction} name="managerEmail" value={documentData.managerEmail} /></td>
          <td>
            <Button variant="success" size="sm" onClick={invite} data-id="addNewManager" data-permission="manager">
              {
                documentData.buttonLoading.addNewManager ?       
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> :
                <>Add</>                    
              }                                
            </Button>
          </td>
        </tr>
      );
    }
    else {
      const mList = documentData.documentPermissions[documentData.documentId].manager.map((manager) => {
        return (<li key={'m' + manager.id}>{manager.name}</li>)
      });
      managers = <tr>
        <th>Document-specific Document Managers</th>
        <td><ul>{mList}</ul></td>
        <td colSpan="2">&nbsp;</td>
      </tr>
    }
    
    let contributors = [];
    if (companyId && (['admin', 'superuser'].includes(topData.permissions[companyId]['permission'])
        || ('manager' === topData.permissions[companyId]['permission'] && myDocument))) {
      
      contributors = documentData.documentPermissions[documentData.documentId].contributor.map((c) => {
        return (
          <tr key={'c' + c.id}>
            <th>Contributor:</th>
            <td>
            {
              higherPermissions.includes(c.id) 
              ? <span style={{"fontWeight":"bold","color":"purple"}}>
                {c.name}
                </span>
              : <>{c.name}</>
            }
            </td>
            <td>
              <Button variant="danger" size="sm" onClick={removeContributor} data-id={c.id}>
                {
                  documentData.buttonLoading['r' + c.id] ?       
                  <Spinner
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  /> :
                  <>Remove from Document</>                    
                }                    
              </Button>
            </td>
            {
              higherPermissions.includes(c.id) ? <td>&nbsp;</td> :
              <td>
                <Button variant="warning" size="sm" onClick={promoteContributor} data-id={'promote' + c.id}>
                  {
                    documentData.buttonLoading['promote' + c.id] ? 
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    /> :
                    <>Promote to Document-Specific Manager</>
                  }
                </Button>
              </td>   
            }                   
          </tr>
        );
      });

      contributors.push(
        <tr key={'newContributor'}>
          <th>Add New Contributor:</th>
          <td><Form.Control type="text" placeholder="Person's Name" onChange={formAction} name="contributorName" value={documentData.contributorName} /></td>
          <td><Form.Control type="text" placeholder="Person's Email" onChange={formAction} name="contributorEmail" value={documentData.contributorEmail} /></td>
          <td>
            <Button variant="success" size="sm" onClick={invite} data-id="addNewContributor" data-permission="contributor">
              {
                documentData.buttonLoading.addNewContributor ?       
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> :
                <>Add</>                    
              }                                
            </Button>
          </td>
        </tr>
      );

    }
    else {
      const cList = documentData.documentPermissions[documentData.documentId].contributor.map((c) => {
        return (
          <li key={'c' + c.id}>
            {
              higherPermissions.includes(c.id) 
              ? <span style={{"fontWeight":"bold","color":"purple"}}>
                {c.name}
                </span>
              : <>{c.name}</>
            }
          </li>
        );
      });
    
      contributors = <tr>
        <th>Contributors</th>
        <td><ul>{cList}</ul></td>
        <td colSpan="2">&nbsp;</td>
      </tr>
    }
  
    const deleteDocument = (event) => {
      event.preventDefault();
    
      if (window.confirm("Are you sure you want to permanently delete this document and all associated information for all users? This can not be undone.")) {
        const buttonLoading = { deleteDocument : true};
        setDocumentData({ ...documentData, 'buttonLoading' : buttonLoading });
      
        const params = {
            email      : topData.userEmail,
            token      : topData.token,
            documentId : documentData.documentId,
            Key        : topData.AccessKeyId,
            Secret     : topData.SecretAccessKey,
            nonce      : topData.nonce,
            groupName  : topData.groupName,
            userId     : topData.userId,
          };
        const url = topData.endpoint + '/document';
        callToBackend(url, params, topData.token, {}, 'DELETE')
        .then((res) => {
          if (200 === res.status) {
            const existing = topData.existing.reduce((prev, curr) => {
              if (curr.documentId !== documentData.documentId) {
                prev.push(curr);
              }
              return prev;
            }, []);
            
            setTopData({ ...topData, page : "welcome", existing: existing });
            setDocumentData(emptyDocument);
          }
          else {
              res.json().then((data) => {
        
                if (data.message) {
                  alert(data.message);
                }
                else {
                  alert('Your login has expired'); 
                }
          
                setTopData(initialState);          
              })      
            }      
        })
        .catch((err) => {
          console.error(err);
          alert('error');
          setDocumentData({ ...documentData, buttonLoading : {} });
        });
      }
      else {
        setDocumentData({ ...documentData, 'buttonLoading' : {} });
      }
    };
  
    const clone = () => {
      setDocumentData({
        ...documentData,
        cloning : true,
      });
      
      const params = {
          email      : topData.userEmail,
          token      : topData.token,
          userId     : topData.userId,
          documentId : documentData.documentId,
          version    : documentData.version, 
          cloneName  : documentData.cloneName,
          Key        : topData.AccessKeyId,
          Secret     : topData.SecretAccessKey,
          nonce      : topData.nonce,
          groupName  : topData.groupName,
        };
      const url = topData.endpoint + '/document';
      callToBackend(url, params, topData.token, {}, 'POST')
      .then((res) => {
        if (401 === res.status) { 
          res.json().then((data) => {
  
            if (data.message) {
              alert(data.message);
            }
            else {
              alert('Your login has expired'); 
            }      
          })
        }
        else if (200 === res.status) {
          res.json().then((data) => {
            setTopData({
              ...topData,
              existing : [ ...topData.existing, data.documentData ],
              page     : 'welcome',
            });
            
            setDocumentData({
              ...documentData,
              cloning : false,
            });            
          });
        }
        
      })
      .catch((err) => {
        console.log(err);
        alert('error cloning document');
      });
    };
  
    return (
       <Container className="p-3">
        <Row className="mb-3 border-bottom">
          <Col>
            <h1>Manage Document</h1>
            <h3>{documentHeading}</h3>
          </Col>
        </Row>
        <Table striped bordered>
          <tbody>
            {admins}
            {superUsers}
            {managers}
            {contributors}
          </tbody>
        </Table>
        <Row className="mb-3 border-bottom">
          <Col>&nbsp;</Col>
        </Row>
        <Row style={{marginTop:15,marginBottom:25}}>
          <Col>
            <strong>New Document Name:</strong>
          </Col>
          <Col>
            <Form.Control type="text" placeholder={documentName} onChange={formAction} name="cloneName" value={documentData.cloneName} />
          </Col>
          <Col>
            {
              documentData.cloning ? 
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> 
              : <Button variant="warning" size="sm" onClick={clone}>Clone Document</Button>
            }
            
          </Col>
        </Row>
        <Row>
          <Col>
            <Button variant="danger" size="lg" onClick={deleteDocument}>
              {
                documentData.buttonLoading.deleteDocument ?       
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                /> :
                <>Delete Document</>                    
              }                                
            </Button>
          </Col>
        </Row>
       </Container>
    );  
  }
  else {
    return (
      <Spinner
        as="span"
        animation="border"
        size="lg"
        role="status"
        aria-hidden="true"
      />
    );
  }  
}

export default ManageSpecificDocument;