import { callToBackend } from "./fetch";

/**
 * Fetches data for the workspace based on the specified types in getData.
 * 
 * @param {Object} topData - Contains user and session information.
 * @param {Function} setTopData - Function to update top-level data.
 * @param {Object} workspaceData - Current workspace data.
 * @param {Function} setWorkspaceData - Function to update workspace data.
 * @param {Function} setLoadData - Function to update loading state.
 * @param {Array} getData - Array of strings indicating data types to fetch ("V", "D", "T").
 */
export async function fetchDataForWorkspace(topData, workspaceData, setWorkspaceData, setLoadData, getData = []) {
  const getVars = getData.includes("V");
  const getDocs = getData.includes("D");
  const getTemps = getData.includes("T");

  // Initialize loading state
  await setLoadData({ isLoading: true, loadingProgress: 0 });

  // Prepare parameters for API calls
  const params = {
    email: topData.userEmail,
    userId: topData.userId,
    Key: topData.AccessKeyId,
    Secret: topData.SecretAccessKey,
    nonce: topData.nonce,
    groupName: topData.groupName,
  };

  // Temporary storage for fetched data
  let vData = {};
  let dData = {};
  let tData = {};

  try {
    // Fetch variables if requested
    if (getVars) {
      const url = `${topData.endpoint}/defaults`;
      const res = await callToBackend(url, params, topData.token, {}, 'GET', false);
      if (res.status === 401) {
        const data = await res.json();
        alert(data.message || "Error retrieving workspace variables.");
      } else if (res.status === 200) {
        const data = await res.json();
        vData = {
          lines: data.lines,
          varSets: data.variables,
        };
      }
      // Update loading progress
      const newProgress = 100 / getData.length;
      await setLoadData({ loadingProgress: newProgress });
    }

    // Fetch documents if requested
    if (getDocs) {
      const url = `${topData.endpoint}/document`;
      const res = await callToBackend(url, params, topData.token, {}, 'GET', true);
      if (res.status === 401) {
        const data = await res.json();
        alert(data.message || "Error retrieving workspace documents.");
      } else if (res.status === 200) {
        const data = await res.json();
        const deadVars = data.dead_vars.reduce((prev, curr) => {
          return { ...prev, [curr.id]: curr };
        }, {});
        const localTimestamp = new Date().getTime();
        dData = {
          docs: data.documents,
          existing: data.documents,
          docs_at: localTimestamp,
          deadVars: deadVars,
        };
      }
      // Update loading progress
      const newProgress = 100 / getData.length;
      await setLoadData({ loadingProgress: newProgress });
    }

    // Fetch templates if requested
    if (getTemps) {
      const url = `${topData.endpoint}/template`;
      const res = await callToBackend(url, params, topData.Authorization, {}, 'GET');
      if (res.status === 401) {
        const data = await res.json();
        alert(data.message || "Error retrieving workspace templates.");
      } else if (res.status === 200) {
        const data = await res.json();
        tData = {
          liveTemps: data.templates,
          draftTemps: data.drafts,
        };
      }
      // Update loading progress
      const newProgress = 100 / getData.length;
      await setLoadData({ loadingProgress: newProgress });
    }

    // Update workspace data with fetched results
    setWorkspaceData({
      ...workspaceData,
      ...vData,
      ...dData,
      ...tData,
    });
  } finally {
    // Reset loading state to indicate completion
    await setLoadData({ isLoading: false, loadingProgress: 100 });
  }
}