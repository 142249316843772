const AsclepiaLib = {
  _flatten : (tree, out, num, prefix) => {
    tree.map((section) => {
      section.num = `${prefix}${num}`;
      out.push(section);
      if (section.sub_sections && section.sub_sections.length) {
        AsclepiaLib._flatten(section.sub_sections, out, 1, `${prefix}${num}.`);
      }
      num++;
    });
  },
  flatten : (tree) => {
    const out  = [];
    let   num  = 2;
    let prefix = "";
    AsclepiaLib._flatten(tree, out, num, prefix);
    return out;
  },
  deepCopy : (data) => {
    return JSON.parse(JSON.stringify(data));
  },
};

export default AsclepiaLib;