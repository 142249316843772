import './App.css';
import { Container, Row, Col } from 'react-bootstrap';

import { useTopDataStore } from "./TopDataStoreProvider";
import { useTemplateDataStore } from "./TemplateDataStoreProvider";
import TemplateSetup from "./TemplateSetup";
import TemplateText from "./TemplateText";
import TemplateSaveChoices from "./TemplateSaveChoices";
import NavSide from "./components/NavSide";

// This sets the subpage within the Template module
function OutletSwitch() {
  // Temporary use of TopDataStoreProvider for page before adding router
  const { topData } = useTopDataStore();  
  switch (topData.page) {
    case "templateSetup":
      return (<TemplateSetup />);
    case "templateText":
      return (<TemplateText />);
    case "templateSaveChoices":
      return (<TemplateSaveChoices />);
    default:
      return (<TemplateText />);
  }
}

export default function TemplateOutletID() {
  const { templateData } = useTemplateDataStore();

  // Define items to appear in the page's side navigation
  const sideNavVars = [
    {
      title: "Outline",
      page: "templateSetup"
    },
    {
      title: "Text Editor",
      page: "templateText"
    },
    {
      title: "Save",
      page: "templateSaveChoices"
    }  
  ];
  
  // Page layout
  return(
    <Container className="mainPage">
      <Row>
        <h2 className="pageTitle">
          {(templateData.templateType == "live") ? templateData.name :
            templateData.draftName || "New Template"}
        </h2>
      </Row>
      <Row> 
        <Col xs={2} className="sectionSidebar">
          <NavSide elements={sideNavVars} />
        </Col>
        <Col>
          <OutletSwitch />           
        </Col>
      </Row>
    </Container>
  );
}
