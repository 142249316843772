import React from 'react';
import './App.css';
import { Container, Table, Row, Col } from 'react-bootstrap';
import { useTopDataStore } from "./TopDataStoreProvider";

// Main Dashboard component
export default function Dashboard() {
  return (
    <Container className="mainPage">
      <Row className="mainPageRow">
        <Col xs="6">
          <p className="welcomeText">
            Welcome to Kinetika by Asclepia! This dashboard will get you oriented to your document, 
            show team notifications, and assist with project management 
            by providing the team a quick view of the phase of document 
            creation.
          </p>
        </Col>
        {/* temporarily disable notifications */}
      </Row>
      <Row className="dashRow">
        <Col xs="10" className="dashboardTable">
          <Table className="intTable">
            <thead>
              <tr>
                <th className="dashHeadLabel">Active Documents</th>
                <th className="dashHeadLabel">Creation</th>
                <th className="dashHeadLabel">Inputs</th>
                <th className="dashHeadLabel">Draft</th>
                <th className="dashHeadLabel">Review</th>
                <th className="dashHeadLabel">Finalization</th>
                <th className="dashHeadLabel">Version</th>
              </tr>
            </thead>
            <tbody>
              <AllTableRows />
            </tbody>
          </Table> 
        </Col>
      </Row>
    </Container>
  );
}

// Component to render all table rows
function AllTableRows() {
  const { topData } = useTopDataStore(); // Access data from the top data store

  // Define bar colors for different document statuses
  const barColors = ['#CD6124', '#454E62', '#93A1A3'];
  const nextBarColor = (i) => {
    return barColors[(i % barColors.length)];
  };
  
  // Check if there are existing documents
  if (topData.existing) {
    return topData.existing.map((doc, index) => {
      const docColor = nextBarColor(index); // Get color for the current document
      
      // Render a table row if the document has a name
      if (doc.documentName) { 
        return (
          <tr key={doc.documentId}>
            <td className="dashRowLabel">{doc.documentName}</td>
            <td className="dashBar" style={{backgroundColor: docColor}}>&nbsp;</td>
            {
              // Conditionally render table cells based on document status
              ['inputs', 'draft', 'review', 'finalization', 'version'].includes(doc.status) 
              ? <td className="dashBar" style={{backgroundColor: docColor}}>&nbsp;</td>
              : <td>&nbsp;</td>
            }
            {
              ['draft', 'review', 'finalization', 'version'].includes(doc.status) 
              ? <td className="dashBar" style={{backgroundColor: docColor}}>&nbsp;</td>
              : <td>&nbsp;</td>
            }
            <td>&nbsp;</td>
            <td>&nbsp;</td>
            <td>&nbsp;</td>
          </tr>
        ); 
      } else {
        return ""; // Return an empty string if no document name
      } 
    });
  } else {
    return []; // Return an empty array if no existing documents
  }
}

/* Temporarily disabling notifications
function Notifications() {

  // NotifcationDataStore only stores information about Notifications. 
  // TO DO: Could this be folded into another data store provider? Is there a benefit to consolidating vs. separating out?
  const { notificationData } = useNotificationsDataStore();

  if (notificationData.notifications && notificationData.notifications.length) {
    return notificationData.notifications.map((note) => {
      if (0 === note.timestamp) {
        return (<></>);
      } else {
        return (
          <Row key={note.id} className="notifyEntry"> 
            <Col>
              <p className="notifyText">
                <span className="notifyName">{note.user_name}</span>
                <br/>
                {note.text}
              </p>            
              <p className="text-secondary notifTime">
                <em>
                  {formatDistance(fromUnixTime(note.timestamp/1000), fromUnixTime(notificationData.referenceDate/1000))} ago
                </em>
              </p>
            </Col>
          </Row>
        );      
      }
    });
  } else {
    return "";
  }
}
*/
