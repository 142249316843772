/*
  Document data store maintains data about an open/active document during editing,
  including any variable values.
  Data related to documents available within a workspace are stored in WorkspaceDataStore.
*/

import { createContext, useState, useContext } from "react";

const DocumentDataStore = createContext();
export const useDocumentDataStore = () => useContext(DocumentDataStore);

export const emptyDocument = {
  documentId      : "",
  variableData   : {},
  variableValues : {},
  buttonLoading  : {},
  version        : "1",
  dead_vars : undefined, // Legacy : need to determine how these are beign used
  cloning : "",
  template_id : "",
  template_version : "0",
  docs : {},
  existing : {},
  docs_at: {},
  deadVars: {},
};

export default function DocumentDataStoreProvider({children}) {
  const [documentData, setDocumentData] = useState(emptyDocument);
  
  return (
    <DocumentDataStore.Provider value={{ documentData, setDocumentData }}>
      {children}
    </DocumentDataStore.Provider>
  );
}