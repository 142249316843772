import './App.css';
import { useEffect, useRef } from "react";
import { Container, Row } from 'react-bootstrap';
import { useTopDataStore } from "./TopDataStoreProvider";
import { useWorkspaceDataStore } from './WorkspaceDataStoreProvider';
import { useLoadDataStore } from './LoadDataStoreProvider';
import NotificationsDataStoreProvider from "./NotificationsDataStoreProvider";

import TemplateOutletID from './TemplateOutletID';
import CreateDocOutlet from "./CreateDocOutlet";
import Dashboard from "./Dashboard";
import ManageDocuments from "./ManageDocuments";
import ManageSpecificDocument from "./ManageSpecificDocument";
import HorizNav from "./HorizNav";
import DocumentList from "./DocumentList";
import DocumentOutletId from './DocumentOutletId';
import TemplatesList from "./TemplatesList";
import VariableOutletId from "./VariableOutletId";

import { statusGET } from './functions/api-calls';
import { fetchDataForWorkspace } from './functions/workspace';
import LoadingModal from './components/LoadingModal';

function Content() {
  const { topData } = useTopDataStore();
  switch (topData.page) {
    case "variables":
      return (<VariableOutletId />);
    case "documentPageDisambiguate":
    case "edit":
    case "synopsis":
    case "studyInformation":
    case "objectives":
    case 'studyDesign':
    case 'patientPopulation':
    case '_variables':  // TO DO: Fix the repeated use of the word "_variables" as a data category --> "characteristics"
      return (<DocumentOutletId />);
    case "manageDocuments":
      return (<ManageDocuments />);
    case "manageSpecificDocument":
      return (<ManageSpecificDocument />);
    case "documentList":
      return (<DocumentList />);
    // Switch for all pages for template management or in the Template Outlet
    case "templates":
      return (<TemplatesList />);
    case "createDocOutlet":
      return (<CreateDocOutlet />);
    case "templateSetup":
    case "templateText":
    case "templateSaveChoices":
    case "templateTitleLayout":
    case "templateTitleEdit": 
      return (<TemplateOutletID />);  
    // Switch for dashboard and profile pages
    case "welcome":
    default:
      return (
        <NotificationsDataStoreProvider>
          <Dashboard />
        </NotificationsDataStoreProvider>
      );
  }
}

export default function Wrapper() {
  const dupCheckRef = useRef(true);

  const { topData, setTopData } = useTopDataStore();
  const { workspaceData, setWorkspaceData } = useWorkspaceDataStore();
  const { loadData, setLoadData } = useLoadDataStore();

  useEffect(() => {
    fetchDataForWorkspace(topData, workspaceData, setWorkspaceData, setLoadData, ["V", "D", "T"]);
  }, []);

  useEffect(() => {
    if (dupCheckRef.current) {
      let interval = null;
      interval = setInterval(() => {
        if (topData.AccessKeyId) {
          statusGET(topData, setTopData, interval);
        }
      }, 600000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [topData.page]);

  return (
    <Container fluid>
      {loadData.isLoading && (
        <LoadingModal progress={loadData.loadingProgress} />
      )}
      <Row id="sidebarRow">
        <HorizNav />
      </Row>
      <Row className="contentBgrd">
        <Content />
      </Row>
    </Container>
  );
}
